import styled from "@emotion/styled"
import tw from "twin.macro"

import { Container } from "../../Styled/Container"

export const StyledContainer = tw(Container)`max-w-lg my-8 md:my-12`
export const Row = tw.section`flex flex-wrap justify-center`
export const Column = styled.div`
  ${tw`w-full md:w-1/2 py-5 md:pt-0`}
  ${({ border }) => (border ? tw`border-b md:border-b-0 md:border-r border-grey` : ``)}
`
export const ColumnInnerWrapper = styled.div`
  ${tw`relative`}
  ${({ isSignIn }) => (isSignIn ? `height: 396px;` : `height: 504px;`)}
`
export const ColumnInner = styled.div`
  ${tw`absolute w-full`}
  ${({ display }) => (display ? tw`block` : tw`hidden`)}
`

export const ColumnLoading = styled.div`
  ${tw`w-full`}
`
